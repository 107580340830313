import React, { useState, useRef, useEffect } from "react";
import {
  Modal,
  Box,
  Typography,
  Button,
  IconButton,
  CircularProgress,
  Alert,
  TextField,
} from "@mui/material";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Inject,
  Filter,
  Page,
  Selection,
} from "@syncfusion/ej2-react-grids";
import { FiDownload, FiTrash } from "react-icons/fi";
import { useMutation, gql, useQuery, useLazyQuery } from "@apollo/client";
import Skeleton from "../../../assets/Animations/Skeleton";
import styled from "styled-components";
import CloseIcon from "@mui/icons-material/Close";
import { stylesProperty } from "@syncfusion/ej2-react-documenteditor";
import styles from "./AnexosModal.module.css";
import { useTranslation } from "react-i18next";

const UPLOAD_ANEXO = gql`
  mutation UploadAnexo(
    $file: Upload!
    $documentKey: String!
    $encryptedPin: String!
    $company: String!
  ) {
    uploadAnexo(
      file: $file
      documentKey: $documentKey
      encryptedPin: $encryptedPin
      company: $company
    ) {
      success
      message
    }
  }
`;

const OBTENER_ANEXOS = gql`
  query ObtenerAnexos($documentKey: String!) {
    obtenerAnexos(documentKey: $documentKey) {
      titulo
      clave
    }
  }
`;

const DELETE_ANEXO = gql`
  mutation DeleteAnexo($documentKey: String!, $filename: String!) {
    deleteAnexo(documentKey: $documentKey, filename: $filename) {
      success
      message
    }
  }
`;

const DESCARGAR_ANEXO = gql`
  query DescargarAnexo(
    $anexoKey: String!
    $encryptedPin: String!
    $company: String!
  ) {
    descargarAnexo(
      anexoKey: $anexoKey
      encryptedPin: $encryptedPin
      company: $company
    ) {
      content
      message
    }
  }
`;

// Estilo del contenedor que alinea los Skeletons en fila
const SkeletonContainer = styled.div`
  display: flex;
  gap: 0px; /* Espacio opcional entre los skeletons */
`;

export default function AnexosModal({
  onClose,
  documentKey,
  onAnexosCount,
  encryptedPin,
  company,
}) {
  const [existingDocuments, setExistingDocuments] = useState([]);
  const [newDocuments, setNewDocuments] = useState([]);
  const [filteredDocuments, setFilteredDocuments] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const fileInputRef = useRef(null);
  const [showMessage, setShowMessage] = useState(false);
  const [operationSuccess, setOperationSuccess] = useState(false);
  const [alertMessage, setAlertMessage] = useState(""); // Añadir estado para el mensaje de alerta
  const { t } = useTranslation();
  const [deleteAnexo, { loading: deleting, error: deleteError }] = useMutation(
    DELETE_ANEXO,
    {
      onCompleted: (data) => {
        if (data.deleteAnexo.success) {
          setOperationSuccess(true);
          setAlertMessage(t("software.alerts.attached.successAlert")); // Mensaje de éxito
        } else {
          setOperationSuccess(false);
          setAlertMessage(t("software.alerts.attached.errorAlert")); // Mensaje de error
        }
        setShowMessage(true);
        refetch();
      },
      onError: () => {
        setOperationSuccess(false);
        setAlertMessage(t("software.alerts.attached.errorAlert"));
        setShowMessage(true);
      },
    }
  );

  const {
    data: dataquery,
    loading: loadingquery,
    error: errorquery,
    refetch,
  } = useQuery(OBTENER_ANEXOS, {
    variables: { documentKey },
  });

  useEffect(() => {
    if (dataquery && dataquery.obtenerAnexos) {
      const docs = dataquery.obtenerAnexos.map((doc) => ({
        ...doc,
        name: doc.titulo,
        enlace: doc.enlace,
        id: doc.titulo,
      }));
      setExistingDocuments(docs);
      setFilteredDocuments(docs);
      onAnexosCount(docs.length); // Notifica al componente padre sobre la cantidad de anexos
    }
  }, [dataquery]);

  const [uploadAnexo, { loading, error }] = useMutation(UPLOAD_ANEXO, {
    onCompleted: (data) => {
      if (data.uploadAnexo.success) {
        setOperationSuccess(true);
        setAlertMessage(t("software.alerts.docs.successAlert")); // Mensaje de éxito al subir
        setShowMessage(true);
        refetch(); // Actualiza la lista de documentos
      } else {
        setOperationSuccess(false);
        setAlertMessage(t("software.alerts.docs.errorAlert")); // Mensaje de error al subir
        setShowMessage(true);
      }
    },
    onError: () => {
      setOperationSuccess(false);
      setAlertMessage(t("software.alerts.docs.errorAlert"));
      setShowMessage(true);
    },
  });

  //Actualizar numero de anexos

  useEffect(() => {
    onAnexosCount(filteredDocuments.length); // Actualiza la cuenta de anexos cuando cambie filteredDocuments
  }, [filteredDocuments, onAnexosCount]);

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const newDocument = {
      name: file.name,
      file: file,
      enlace: URL.createObjectURL(file),
      id: file.name,
    };

    setNewDocuments((prevDocs) => [...prevDocs, newDocument]);

    // Subir el archivo automáticamente
    try {
      await uploadAnexo({
        variables: { file, documentKey, encryptedPin, company },
      });
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const handleSearchChange = (event) => {
    const term = event.target.value.toLowerCase();
    setSearchTerm(term);
    if (term) {
      setFilteredDocuments(
        [...existingDocuments, ...newDocuments].filter((doc) =>
          doc.name.toLowerCase().includes(term)
        )
      );
    } else {
      setFilteredDocuments([...existingDocuments, ...newDocuments]);
    }
  };

  const downloadDocument = (url, fileName) => {
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName || "download";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const handleDeleteDocument = (filename) => {
    deleteAnexo({ variables: { documentKey, filename } });
  };

  const [descargarAnexo, { loading: loadingDescarga, error: errorDescarga }] =
    useLazyQuery(DESCARGAR_ANEXO);

  const downloadAnexo = async (anexoKey, titulo) => {
    try {
      const { data } = await descargarAnexo({
        variables: {
          anexoKey,
          encryptedPin, // Pasar encryptedPin
          company, // Pasar company
        },
      });

      if (data && data.descargarAnexo && data.descargarAnexo.content) {
        const base64Content = data.descargarAnexo.content;
        const byteCharacters = atob(base64Content);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray]);
        const url = URL.createObjectURL(blob);

        // Crear un enlace temporal para descargar el archivo
        const a = document.createElement("a");
        a.href = url;
        a.download = titulo;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

        // Liberar el objeto URL
        URL.revokeObjectURL(url);
      } else {
        console.error(
          "Error al descargar el anexo:",
          data.descargarAnexo.message
        );
      }
    } catch (error) {
      console.error("Error al ejecutar la consulta:", error);
    }
  };

  const downloadButtonTemplate = (props) => (
    <IconButton onClick={() => downloadAnexo(props.clave, props.name)}>
      <FiDownload />
    </IconButton>
  );

  const deleteButtonTemplate = (props) => (
    <IconButton onClick={() => handleDeleteDocument(props.name)}>
      <FiTrash />
    </IconButton>
  );

  return (
    <Modal open={true} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { xs: "90%", sm: "70%", md: "50%" },
          bgcolor: "background.paper",
          borderRadius: 2,
          boxShadow: 24,
          p: 4,
        }}
      >
        <IconButton
          onClick={onClose}
          sx={{ position: "absolute", top: 8, right: 10 }}
        >
          <CloseIcon />
        </IconButton>
        <Typography variant="h6" component="h2">
          {t("software.attached.add")}
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mt: 2,
            mb: 2,
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={() => fileInputRef.current.click()}
            sx={{ textTransform: "none" }}
            className={styles.añadiranexoboton}
          >
            {t("software.attached.add")}
          </Button>
          <TextField
            variant="outlined"
            size="small"
            placeholder="Buscar anexo..."
            value={searchTerm}
            onChange={handleSearchChange}
            sx={{ ml: 2 }}
          />
        </Box>
        <input
          type="file"
          onChange={handleFileUpload}
          ref={fileInputRef}
          style={{ display: "none" }}
        />
        <Box sx={{ mt: 3, mb: 2 }}>
          {loadingquery ? (
            <SkeletonContainer>
              <Skeleton />
              <Skeleton />
            </SkeletonContainer>
          ) : (
            <GridComponent
              dataSource={filteredDocuments}
              allowPaging={true}
              pageSettings={{ pageCount: 5 }}
              height="200px"
            >
              <ColumnsDirective>
                <ColumnDirective field="name" headerText={t("software.attached.title")} width="auto" />
                <ColumnDirective
                  headerText={t("software.attached.downloadTitle")}
                  template={downloadButtonTemplate}
                  width="auto"
                />
              </ColumnsDirective>
              <Inject services={[Selection, Page, Filter]} />
            </GridComponent>
          )}
        </Box>
        {showMessage && (
          <Alert
            onClose={() => setShowMessage(false)}
            severity={operationSuccess ? "success" : "error"}
            sx={{ width: "100%" }}
          >
            {alertMessage}
          </Alert>
        )}
        {(loading || deleting) && <CircularProgress sx={{ mt: 2 }} />}
      </Box>
    </Modal>
  );
}
