import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Typography,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import video3 from "../../../assets/Animations/Tutorial-Comentarios.mp4";
import video1 from "../../../assets/Animations/Tutorial-Importar.mp4";
import video4 from "../../../assets/Animations/Tutorial-Anexos.mp4";
import video2 from "../../../assets/Animations/Tutorial-Editor.mp4";
import video5 from "../../../assets/Animations/Tutorial-Respuesta.mp4";
import {
  Bs1Circle,
  Bs2Circle,
  Bs3Circle,
  Bs4Circle,
  Bs5Circle,
} from "react-icons/bs";
import { useTranslation } from "react-i18next";

const HelpModal = ({ open, onClose }) => {
  const { t } = useTranslation();
  const [isVideoExpanded, setVideoExpanded] = useState(null);

  const handleVideoClick = (videoIndex) => {
    setVideoExpanded(videoIndex);
  };

  const handleCloseVideo = () => {
    setVideoExpanded(null);
  };

  const sections = [
    {
      id: 1,
      icon: <Bs1Circle />,
      title: t("software.cliente.helpCliente.editorTitle"),
      text: t("software.cliente.helpCliente.editorText"),
      video: video2,
    },
    {
      id: 2,
      icon: <Bs2Circle />,
      title: t("software.cliente.helpCliente.commentsTitle"),
      text: t("software.cliente.helpCliente.commentsText"),
      video: video3,
    },
    {
      id: 3,
      icon: <Bs3Circle />,
      title: t("software.cliente.helpCliente.importTitle"),
      text: t("software.cliente.helpCliente.importText"),
      video: video1,
    },
    {
      id: 4,
      icon: <Bs4Circle />,
      title: t("software.cliente.helpCliente.annexesTitle"),
      text: t("software.cliente.helpCliente.annexesText"),
      video: video4,
    },
    {
      id: 5,
      icon: <Bs5Circle />,
      title: t("software.cliente.helpCliente.responseTitle"),
      text: t("software.cliente.helpCliente.responseText"),
      video: video5,
    },
  ];

  return (
    <>
      {/* Modal Principal */}
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth="md"
        fullWidth
        sx={{
          "& .MuiDialog-paper": {
            height: "80vh",
            display: "flex",
            flexDirection: "column",
          },
        }}
      >
        {/* Cabecera */}
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            fontSize: "1.8rem",
            fontWeight: "bold",
          }}
        >
          <Typography variant="h6" component="div">
          {t("software.cliente.helpCliente.title")}
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        {/* Contenido Scrolleable */}
        <DialogContent
          dividers
          sx={{
            overflowY: "auto",
            padding: "20px",
          }}
        >
          {sections.map((section) => (
            <div key={section.id} style={{ marginBottom: "40px" }}>
              <Grid container spacing={1} alignItems="center">
                <Grid item xs={12}>
                  <Typography
                    variant="h5"
                    sx={{
                      fontWeight: "bold",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {React.cloneElement(section.icon, {
                      size: "1.5rem",
                      style: { verticalAlign: "middle" },
                    })}
                    <span style={{ paddingLeft: "10px" }}>{section.title}</span>
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={3} alignItems="center">
                {/* Explicación a la izquierda */}
                <Grid item xs={12} sm={6}>
                  <Typography
                    variant="body1"
                    gutterBottom
                    sx={{ paddingLeft: "30px" , marginTop: "10px"}}
                  >
                    {section.text}
                  </Typography>
                </Grid>

                {/* Video a la derecha */}
                <Grid item xs={12} sm={6}>
                  <div
                    style={{
                      position: "relative",
                      transition: "transform 0.3s ease-in-out",
                      marginLeft: "10px",
                    }}
                  >
                    <video
                      src={section.video}
                      autoPlay
                      loop
                      muted
                      playsInline
                      onClick={() => handleVideoClick(section.id)}
                      style={{
                        width: "100%",
                        height: "auto",
                        borderRadius: "8px",
                        transition: "transform 0.3s ease-in-out",
                        cursor: "pointer",
                      }}
                      onMouseEnter={(e) => {
                        e.target.style.transform = "scale(1.05)";
                      }}
                      onMouseLeave={(e) => {
                        e.target.style.transform = "scale(1)";
                      }}
                    />
                  </div>
                </Grid>
              </Grid>
            </div>
          ))}
        </DialogContent>
      </Dialog>

      {/* Modal flotante para el video expandido */}
      {isVideoExpanded && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            backgroundColor: "rgba(0, 0, 0, 0.9)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 9999,
          }}
        >
          <video
            src={sections.find((s) => s.id === isVideoExpanded)?.video}
            autoPlay
            loop
            muted
            playsInline
            style={{
              maxWidth: "90vw",
              maxHeight: "90vh",
              borderRadius: "8px",
            }}
          />
          <IconButton
            onClick={handleCloseVideo}
            sx={{
              position: "absolute",
              top: "10px",
              right: "10px",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              color: "white",
              fontSize: "2rem",
              zIndex: 10000,
              "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.7)",
              },
            }}
          >
            <CloseIcon fontSize="large" />
          </IconButton>
        </div>
      )}
    </>
  );
};

export default HelpModal;
