import React from "react";
import { Box, TextField, Typography, InputAdornment } from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import "react-quill/dist/quill.snow.css";
import ReactQuill from "react-quill";
import { useTranslation } from "react-i18next";

const Content3 = ({ emailData, handleInputChange, emisor }) => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        mt: 0,
        maxHeight: "80vh",
        overflowY: "auto",
        paddingRight: "16px",
      }}
    >
      <Typography variant="h6" gutterBottom>
      {t("software.cliente.stepperModalCliente.content3.emailTitle")}
      </Typography>
      <TextField
        fullWidth
        label={t("software.cliente.stepperModalCliente.content3.toLabel")}
        value={emisor} // Mostrar el valor de emisor
        InputProps={{
          readOnly: true,
          endAdornment: (
            <InputAdornment position="end">
              <LockIcon />
            </InputAdornment>
          ),
        }}
        sx={{ mb: 2 }}
      />
      <TextField
        fullWidth
        label={t("software.cliente.stepperModalCliente.content3.subjectLabel")}
        value={emailData.subject || ""} // Asegúrate de que el valor sea controlado
        onChange={(e) => handleInputChange("subject")(e)} // Llamar a la función que maneja los cambios
        sx={{ mb: 2 }}
      />
      <Typography variant="subtitle1" gutterBottom>
      {t("software.cliente.stepperModalCliente.content3.messageLabel")}
      </Typography>
      <ReactQuill
        value={emailData.message || ""} // Asegúrate de que el valor sea controlado
        onChange={(value) =>
          handleInputChange("message")({ target: { value } })
        } // Capturar el contenido de ReactQuill
        theme="snow"
        style={{ height: "30vh", marginBottom: "60px" }}
      />
    </Box>
  );
};

export default Content3;
