import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "./Confirmation.module.css";
import { IoCheckmarkCircleOutline } from "react-icons/io5";
import { useTranslation } from "react-i18next";


export default function PaymentConfirmation() {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (!searchParams.has("email")) {
      navigate("/"); // Redirige a la página de inicio si no se accede correctamente
    }
  }, [location, navigate]);

  const email = new URLSearchParams(location.search).get("email");

  return (
    <div className={styles.container}>
      
      <IoCheckmarkCircleOutline className={styles.iconcheck} />

      
      <h2 className={styles.title}>{t("web.pricing.confirmtitle")}</h2>
      <p className={styles.message}>
      {t("web.pricing.confirmdescription1")}<span className={styles.email}>{email}</span>{t("web.pricing.confirmdescription2")}
      </p>
    </div>
  );
}
