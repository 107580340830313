import React from "react";
import { Container, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const NotFound = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleHomeClick = () => {
    navigate("/");
  };

  return (
    <Container
      component="main"
      maxWidth="xs"
      style={{ textAlign: "center", marginTop: "50px", height: "100vh" }}
    >
      <Typography variant="h2" component="h1" gutterBottom>
      {t("software.signUp.notFound.code")}
      </Typography>
      <Typography variant="h5" component="h2" gutterBottom>
        {t("software.signUp.notFound.page")}
      </Typography>
      <Typography variant="body1" gutterBottom>
      {t("software.signUp.notFound.notExist")}
      </Typography>
      <Button variant="contained" color="primary" onClick={handleHomeClick}>
      {t("software.signUp.notFound.goTo")}
      </Button>
    </Container>
  );
};

export default NotFound;
