import React, { useState, useEffect } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import NotFound from "../../Signup/NotFound";
import { Box, Typography, Button } from "@mui/material";
import { IoCheckmarkCircleOutline } from "react-icons/io5";
import { IoIosArrowRoundForward } from "react-icons/io";
import styles from "./Success.module.css";
import { Link } from "react-router-dom";
import home from "../../../assets/images/Group-34.webp";
import Lottie from "react-lottie";
import animationData from "../../../assets/Animations/SafeCon.json";
import { useTranslation } from "react-i18next";

const Success = () => {
  const { t } = useTranslation();
  const { destinatario } = useParams(); // Extraemos el destinatario como parámetro de la URL
  const location = useLocation();
  const navigate = useNavigate();

  const destinatarioFinal = destinatario || location.state.destinatario;

  const defaultOptions = {
    loop: true, // Cambia a false si no quieres que se repita
    autoplay: true, // Comienza automáticamente
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  // Si no hay destinatario en los params o en el estado, redirige a NotFound
  if (!destinatario && !location.state?.destinatario) {
    return <NotFound />;
  }

  // Si todo está correcto, renderiza el componente de éxito
  return (
    <Box
      sx={{
        textAlign: "center",
        mt: 0,

        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <IoCheckmarkCircleOutline size={72} color="#61d1b5" />
      <Typography
        variant="h5"
        sx={{
          mt: 1,
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          textAlign: "center",
        }}
      >
         {t("software.cliente.success.fullMessage", { dest: destinatarioFinal })}{" "}
      </Typography>

      <Box sx={{ textAlign: "center" }}>
        <Lottie
          isClickToPauseDisabled={true}
          options={defaultOptions}
          height={"70vh"}
        />
      </Box>

      <Button
        variant="contained"
        onClick={() => navigate("/")}
        sx={{
          py: 1.5,
          fontSize: "1rem",
          borderRadius: "25px",
          textTransform: "none",
          background: "#61d1b5",
          color: "#fff",
        }}
      >
        {t("software.cliente.success.discover")}{" "}
        <IoIosArrowRoundForward
          style={{ marginLeft: "10px", fontSize: "29px" }}
        />
      </Button>
    </Box>
  );
};

export default Success;
