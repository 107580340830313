import React, { useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { gql, useMutation } from "@apollo/client";
import styles from "./InvitationHandler.module.css"; // Importar los estilos del módulo CSS
import Lottie from "react-lottie";
import animationData from "../../assets/Animations/SafeCon.json";
import { useTranslation } from "react-i18next";

const defaultOptions = {
  loop: true, // Cambia a false si no quieres que se repita
  autoplay: true, // Comienza automáticamente
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const ACCEPT_INVITATION = gql`
  mutation AcceptInvitation(
    $token: String!
    $nombre: String!
    $cargo: String!
  ) {
    acceptInvitation(token: $token, nombre: $nombre, cargo: $cargo) {
      success
      message
    }
  }
`;

const REJECT_INVITATION = gql`
  mutation RejectInvitation($token: String!) {
    rejectInvitation(token: $token) {
      success
      message
    }
  }
`;

const InvitationHandler = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const token = searchParams.get("token");
  const nombreEmpresa = searchParams.get("nombreEmpresa");

  const [nombre, setNombre] = useState("");
  const [cargo, setCargo] = useState("");

  const [acceptInvitation] = useMutation(ACCEPT_INVITATION);
  const [rejectInvitation] = useMutation(REJECT_INVITATION);

  useEffect(() => {
    if (!token) {
      console.error("Missing token parameter");
      navigate("/404"); // Redirigir a la página 404
      return;
    }
  }, [token, navigate]);

  const handleAccept = () => {
    if (!nombre || !cargo) {
      alert(t("software.signUp.invitation.empty"));
      return;
    }

    acceptInvitation({ variables: { token, nombre, cargo } })
      .then(({ data }) => {
        if (data.acceptInvitation.success) {
          navigate("/dashboard");
        } else {
          alert(data.acceptInvitation.message);
        }
      })
      .catch((error) => console.error("Error:", error));
  };

  const handleReject = () => {
    rejectInvitation({ variables: { token } })
      .then(({ data }) => {
        if (data.rejectInvitation.success) {
          navigate("/");
        } else {
          alert(data.rejectInvitation.message);
        }
      })
      .catch((error) => console.error("Error:", error));
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <h2 className={styles.title}>
        {t("software.signUp.invitation.title", {nombreEmpresa: nombreEmpresa})}
        </h2>
        <p className={styles.description}>
          {t("software.signUp.invitation.description")}
        </p>
        <input
          type="text"
          placeholder={t("software.signUp.rootExistForm.name")}
          value={nombre}
          onChange={(e) => setNombre(e.target.value)}
          className={styles.input}
          maxLength={100}
        />
        <input
          type="text"
          placeholder={t("software.signUp.rootExistForm.position")}
          value={cargo}
          onChange={(e) => setCargo(e.target.value)}
          className={styles.input}
          maxLength={100}
        />
        <div className={styles.buttoncontainer}>
          <button className={styles.button} onClick={handleAccept}>
          {t("software.accept")}
          </button>
          <button className={styles.button} onClick={handleReject}>
          {t("software.decline")}
          </button>
        </div>
      </div>
      <div className={styles.animationContainer}>
        <Lottie
          isClickToPauseDisabled={true}
          options={defaultOptions}
          width={"100%"}
        />
      </div>
    </div>
  );
};

export default InvitationHandler;
